import React, { useEffect, useState } from 'react'
import Text from '@cmp/Text'
import Button from '@src/components/Button'
import cn from 'classnames'
import { useStore } from '../Step-store'
import { navigate } from 'gatsby'
import { useLanguage } from '@src/templates'
import { MEETING_MAX } from '@src/utils/contants'

export const Confirmation = () => {
  const {
    clearStore,
    numGuests,
  } = useStore()

  const { t, locale } = useLanguage()
  const [isLargeEvent, setIsLargeEvent] = useState(false)
  useEffect(() => {
    setIsLargeEvent(numGuests >= MEETING_MAX)
    clearStore()
  }, [])

  return (
    <div
      className='flex flex-col lg:flex-row border-t'
      style={{
        minHeight: 'calc(100vh - 82px)',
      }}
    >
      <div 
        className='absolute inset-0 bg-h-full bg-no-repeat bg-quasi-alt-3 z-[-1] top-[82px] opacity-40'
        style={{
          backgroundPositionX: '-400px',
        }}
      >
      </div>
      <div className='w-full pt-[10rem] sm:pt-[16rem] gutter text-center flex flex-col'>
        <Text as='h1' weight='heavy' className='text-[3rem] leading-[50px]'>
          {!isLargeEvent ? t('thank-you-for-booking-at-harpa') : t('thank-you-for-your-application')}
        </Text>
        <div className='w-full flex sm:justify-around'>
          {!isLargeEvent ? (
            <Text as='p' variant='base' className='mt-5 w-full sm:w-[60%]'>
              {t('payment-success')}
            </Text>
          ) : (
            <Text as='p' variant='base' className='mt-5 w-full sm:w-[60%]'>
              {t('application-success')}
            </Text>
          )}
        </div>
        <Text as='p' variant='base' className='mt-5'>
          {t('sincerely-harpa')}
        </Text>
        <div className='flex justify-around'>
          <Button
            onClick={() => {
              // Here I want to redirect to the homepage
              locale === 'is' ? navigate('/') : navigate('/en')
            }}
            variant='yellow'
            className={cn('min-w-[280px] sm:min-w-[40%] mb-12 max-h-[56px] mt-5')}
            icon
          >
            {t('back-to-frontpage')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Confirmation
